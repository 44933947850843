<template>
    <div class="c-error-view">
        <h1 class="u-a4">404</h1>
        <h2 class="u-a3">{{$t('Ova stranica ne postoji')}}</h2>
        <a-button type="primary" @click="goToHome">{{$t('Vrati se na početnu')}}</a-button>
    </div>

</template>

<script lang="ts">
    import { Vue, Component } from 'vue-property-decorator';
    import { RouteNames } from '@/enums/routes/RouteNames';

    @Component({
        name: 'ErrorView',
    })
    export default class ErrorView extends Vue {
        private goToHome() {
            this.$router.push({name: RouteNames.home});
        }
    }
</script>

<style scoped>
    .c-error-view {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

</style>
